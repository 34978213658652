import React, { useEffect, useState } from "react"
import Stepper from "../tunnel/stepper.component"
import { tunnelCustomerSteps } from "../tunnel/customer/tunnelCustomer.component"
import { usePaymentContext } from "./payment.provider"
import Modal from "../../components/modal/modal.component"
import useNavigation from "../navigation/useNavigation"
import { StringParam, useQueryParam } from "use-query-params"
import { submitPayment } from "./payment.api"
import { useTranslation } from "../translation/translate.component"
import Loader from "../../components/loader/loader.component"
import Block from "../../components/block/block.component"

export default function StepLoading () {
  const { navigate } = useNavigation()
  const [isLoading, setIsLoading] = useState(false)
  const { step2, isReady, sessionId } = usePaymentContext()
  const [redirectStatus] = useQueryParam(`redirect_status`, StringParam)
  const [paymentIntent] = useQueryParam(`payment_intent`, StringParam)
  const { t } = useTranslation()
  const loadingMessage = t(`common:payment.page_step4_loading_text`)

  useEffect(
    function handleRedirection () {
      setIsLoading(true)
      if (isReady) {
        if (redirectStatus === `succeeded` && paymentIntent) {
          const toSend = {
            payment: {
              type: `stripe`,
              paymentInfos: { paymentIntentId: paymentIntent },
            },
            sessionId,
          }
          submitPayment(toSend)
            .then(() => {
              setIsLoading(false)
              navigate(`paymentSuccess`, {
                state: {
                  email: step2.email,
                },
              })
            })
            .catch(() => {
              setIsLoading(false)
              navigate(`paymentFail`)
            })
        } else {
          setIsLoading(false)
          navigate(`paymentFail`)
        }
      }
    },
    [isReady],
  )

  return (
    <Block>
      <Stepper steps={tunnelCustomerSteps} currentStep={3} />
      <Modal visible={isLoading} closeButton={false} modalComponent={() => <Loader message={loadingMessage} />} />
    </Block>
  )
}
