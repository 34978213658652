import React from "react"
import styled, { keyframes } from "styled-components"
import PropTypes from "prop-types"
import { colors } from "../../modules/structure/theme"
import { flexCenter } from "../../modules/structure/theme/styles.component"

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const LoadingImage = styled.div`
  ${flexCenter};
`
const LoadingText = styled.div`
  ${flexCenter};
  margin-top: 23px;
  margin-bottom: 40px;
`
// Create the keyframes
const LoadingSpin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const LoadingSpinner = styled.div`
  margin-bottom: 39px;
  width: 90px;
  height: 90px;
  &:after {
    content: " ";
    display: block;
    width: 52px;
    height: 52px;
    margin: 10px;
    border-radius: 50%;
    border: 8px solid;
    border-color: ${colors(`primary`)} transparent ${colors(`primary`)} transparent;
    animation: ${LoadingSpin} 1.8s linear infinite;
  }
`

export default function Loader ({ message }) {
  return (
    <LoadingWrapper data-testid="turning_loading">
      <LoadingText>{message}</LoadingText>
      <LoadingImage>
        <LoadingSpinner />
      </LoadingImage>
    </LoadingWrapper>
  )
}

Loader.propTypes = {
  message: PropTypes.string.isRequired,
}
